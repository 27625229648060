@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Micro5";
  src: url("../public/fonts/Micro5-Regular.ttf");
}

@font-face {
  font-family: "Workbench";
  src: url("../public/fonts/Workbench-Regular.ttf");
}

@font-face {
  font-family: "Silkscreen";
  src: url("../public/fonts/Silkscreen-Regular.ttf");
}

@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/Jura-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/Jura-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/Jura-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/Jura-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/Jura-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #04080F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {

}
